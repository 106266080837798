import React from 'react'
import Content from '../Content'
import { kebabCase } from 'lodash'
import { Link } from 'gatsby'

const ArticleTemplate = ({
  content,
  contentComponent,
  date,
  cover,
  meta_title,
  meta_desc,
  tags,
  title,
}) => {
  const PostContent = contentComponent || Content
  return (
    <div>
      <h1 className='mt-8 text-4xl font-light'>
        {title}
      </h1>

      <div className='my-4 text-sm text-gray-600'>
        {date}
      </div>

      <img src={cover} alt={title} className='object-cover w-full h-64 my-4' />

      <PostContent content={content} className='markdown' />

      <ul className='mt-16 mb-4'>
        {(tags && tags.length)
          ? tags.map(tag => (
            <li key={tag + `tag`} className='inline-block px-3 py-1 mr-2 text-sm font-semibold text-gray-700 bg-gray-200 rounded-full last:mr-0 hover:bg-gray-700 hover:text-gray-100 transition ease-in-out duration-300'>
              <Link to={`/tags/${kebabCase(tag)}/`}>#{tag}</Link>
            </li>
          ))
          : null}
      </ul>

      <hr />
    </div>
  )
}

export default ArticleTemplate
