import React, { Component } from 'react'
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  TelegramShareButton,
  RedditShareButton,
  FacebookShareCount,
  RedditShareCount,
  FacebookIcon,
  TwitterIcon,
  TelegramIcon,
  LinkedinIcon,
  RedditIcon,
} from 'react-share'
import config from '../../../config'
import './styles.css'

class Share extends Component {
  render () {
    const { title, slug, excerpt } = this.props
    const realPrefix = config.pathPrefix === '/' ? '' : config.pathPrefix
    const url = config.siteUrl + realPrefix + slug

    const filter = count => (count > 0 ? count : '')

    const iconStyles = { iconFillColor: '#718096', bgStyle: { fill: 'none' }, size: 36 }

    return (
      <div className='social-links'>
        <RedditShareButton url={url} title={title}>
          <RedditIcon {...iconStyles} />
          <RedditShareCount url={url}>
            {count => <div className='share-count'>{filter(count)}</div>}
          </RedditShareCount>
        </RedditShareButton>

        <TwitterShareButton url={url} title={title}>
          <TwitterIcon {...iconStyles} />
        </TwitterShareButton>

        <FacebookShareButton url={url} quote={excerpt}>
          <FacebookIcon {...iconStyles} />
          <FacebookShareCount url={url}>
            {count => <div className='share-count'>{filter(count)}</div>}
          </FacebookShareCount>
        </FacebookShareButton>

        <LinkedinShareButton
          url={url}
          title={title}
          description={excerpt}
        >
          <LinkedinIcon {...iconStyles} />
        </LinkedinShareButton>

        <TelegramShareButton url={url}>
          <TelegramIcon {...iconStyles} />
        </TelegramShareButton>
      </div>
    )
  }
}

export default Share
